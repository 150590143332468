import React, {createContext, useState} from 'react';

export const PopupContext = createContext();

export const PopupProvider = ({children}) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		document.body.classList.toggle('overflow-hidden');
		setIsOpen(!isOpen);
	};

	return (
		<PopupContext.Provider
			value={{
				isOpen,
				toggle
			}}
		>
			{children}
		</PopupContext.Provider>
	);
};
